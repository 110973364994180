import React from 'react';
import { ModalWraper } from '../UI/styledConstants';
import IconClose from '../../assets/images/IconClose';
import logoBlack from '../../assets/images/logoBlack.svg';
import backgroundImg from '../../assets/images/bg-img.png';
import { downloadDivAsImage, numberToWords } from '../../utils/common';
const Receipt = ({ modalData, onCloseModal }) => {
	const handleDownloadClick = (title) => {
		const divElement = document.getElementById('model');
		downloadDivAsImage(divElement, title);
	};
	
	return (
		<ModalWraper className='recepit' url={backgroundImg}>
			<div className="modal modal-success" id="model">
				<div className="flex modal-header justify-between">
					<div className="logo">
						<img src={logoBlack} alt={'logo'} />
						<div>
							<p>Invoicing and payments</p>
							<p>powered by Start Traders </p>
						</div>
					</div>

					<div className="buttons">
						<button className="button-solid" style={{ marginRight: '8px' }} onClick={() => handleDownloadClick('send money receipt')}>
							Download
						</button>
						<button className="button-solid" onClick={() => window.print()}>
							Print
						</button>
					</div>
				</div>
				<div className="modal-close" onClick={() => onCloseModal()}>
					<IconClose />
				</div>
				<div className="text-center modal-title">
					<h2 className="transactionMsg">{modalData?.txnMessage}</h2>
					<h3 className="amount">₹ {modalData?.amount}</h3>
					<p className="amount-text">{numberToWords(modalData?.amount)} rupee only</p>
				</div>
				<div className="modal-body">
					<div className="txn-daital">
						<div className="flex justify-between txn-daital-h">
							<div>Send To</div>
							<div></div>
						</div>
						<div className="flex justify-between txn-desc">
							<div className="txn-desc-right">
								<div>
									<span className="heading">Name :</span>
									<span>{modalData?.accountHolderName}</span>
								</div>
								

								<div>
									<span className="heading">Account No. :</span>
									<span>{modalData?.accountNumber}</span>
								</div>
								<div>
									<span className="heading">Ifsc Code :</span>
									<span>{modalData?.ifsc}</span>
								</div>
								<div>
									<span className="heading">Transaction ID :</span>
									<span>{modalData?.transactionId}</span>
								</div>
							</div>
							<div className="txn-desc-left">
								<div>
									<span>Date: </span>
									<span>{modalData?.date}</span>
								</div>
								<div>
									<span>Order ID: </span>
									<span>{modalData?.orderId}</span>
								</div>
								<div>
									<span>UTR No.: </span>
									<span>{modalData?.utr}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <div className="modal-footer">
							<button className="button-solid">
								Print
							</button>
						</div> */}
			</div>
		</ModalWraper>
	);
};

export default Receipt;
