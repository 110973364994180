import React from 'react';
import { Wrapper } from './style';
import img from '../../assets/images/bharatBanner.png';
import bgbanner1 from '../../assets/images/banner-bg-1.png';
import bgbannermobile2 from '../../assets/images/banner-2-mobile.png';
import bgbanner2 from '../../assets/images/banner-bg-2.png';
import bgbannermobile from '../../assets/images/bg-banner-2-mobile.png';

export default function OfferBanner() {
	return (
		<Wrapper className="d-m-none flex gap-10" bgImg={bgbanner1} bgimg2={bgbanner2}>
			<div className="banner-card banner-left flex gap-10">
				<div className="img-banner">
					<img src={bgbannermobile} alt="img1" />
				</div>
				<div className="descr">
					<h3 className="">Win Cashback Every Day</h3>
					<p>The best B2B and Banking services provider</p>
				</div>
			</div>
			<div className="banner-card banner-right flex">
				<div className="img-banner">
					<img src={bgbannermobile2} alt="img1" />
				</div>
				<div className="descr">
					<h3>Recharges, Bill Payments</h3>
					<p>The best B2B and Banking services provider</p>
				</div>
			</div>
		</Wrapper>
	);
}
