import styled from 'styled-components';
import img from '../../assets/images/bharatBanner.png'

export const Wrapper = styled.div`
    

	margin: -30px;
	 height: 318px;
	 border-radius: 10px;
	 gap: 10px;
	 .banner-card{
  width: 50%;
  background-size: cover;
  border-radius: 10px;
  padding: 10px;
  .img-banner{
		width: 50%;
		align-self: flex-end;
		img{
			width: 100%;
		}
	}
  .descr{
	align-self: center;
	text-align: left  !important;
	word-spacing: 4px;
	font-size: 16px!important;
	width: 50%;
	h3{
		margin-bottom: 10px;
	}
	
  }
}
.banner-left{
	background-image: url(${props => props.bgImg});
	
}
.banner-right{
	background-image: url(${props => props.bgimg2});
	color: #fff;
}
 

	
`;
