import { NavLink, Link, useNavigate, useLocation } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import jwt from 'jsonwebtoken';

import { Wrapper } from './style';
import { useState, useEffect, useContext } from 'react';

import { isEmpty } from 'lodash';
import { AlertWrapper } from '../UI/styledConstants';
import { GlobleContaxt } from '../../Context/GlobleContaxt';
import { getApiPermission, getUserProfileRequest, getUserWalletRequest } from '../../utils/api';
import caretDown from '../../assets/images/caret-down.svg';
import aeps from '../../assets/images/aeps-balance.svg';
import main from '../../assets/images/main-balance.svg';

function Component({ handleModal = () => {} }) {
	const [message, setMessage] = useState({});
	const [toggle, setToggle] = useState(false);
	const [balance, setBalance] = useState({});

	const navigate = useNavigate();

	const { pathname } = useLocation();
	useEffect(() => {
		setToggle(false);
	}, [pathname]);

	const { userDetails, setUserDetails, setApiPermission } = useContext(GlobleContaxt);

	useEffect(() => {
		if (sessionStorage.getItem('ID Token')) {
			const user_role = sessionStorage.getItem('UserRole');
			getUserProfileRequest().then((resp) => {
				if (resp?.success) {
					setUserDetails(resp?.data);
				} else {
				}
			});

			getApiPermission(user_role).then((resp) => {
				if (resp?.success) {
					if (isEmpty(resp?.data?.permissionList)) {
						setApiPermission([]);
					} else {
						setApiPermission(resp?.data?.permissionList);
					}
				}
			});

			getUserWalletRequest('').then((resp) => {
				if (resp?.success) {
					setBalance(resp?.data);
				}
			});
		}
	}, [sessionStorage.getItem('ID Token')]);

	useEffect(() => {
		if (sessionStorage.getItem('ID Token')) {
			const token = sessionStorage.getItem('ID Token');
			const decoded = jwt.decode(token);
			const Intervalid = setInterval(() => {
				try {
					if (decoded) {
						const expirationTimestamp = decoded.exp;

						const expirationDate = new Date(expirationTimestamp * 1000);
						if (expirationDate <= Date.now()) {
							logOut('exp');
							clearInterval(Intervalid);
						}
					} else {
						console.error('Token is not valid');
					}
				} catch (error) {
					console.error('An error occurred:', error.message);
				}
			}, 6000);
		}
	}, [sessionStorage.getItem('ID Token')]);

	const logOut = (key) => {
		sessionStorage.removeItem('ID Token');
		sessionStorage.removeItem('UserRole');
		if (key === 'logout') {
			setMessage({ type: 'success', msg: 'User logout successfully', place: 'globle' });
		}
		if (key === 'exp') {
			setMessage({ type: 'error', msg: 'Your Session Expired Please Login Again To Continue', place: 'globle' });
		}

		setUserDetails({});
		navigate('/dashboard', { replace: true });
	};

	useEffect(() => {
		if (!isEmpty(message)) {
			setTimeout(() => {
				setMessage({});
			}, 5000);
		}
	}, [message]);

	const spinner = () => {
		setToggle(!toggle);
	};

	return (
		<Wrapper className="container">
			{Object.keys(userDetails) == 0 ? (
				''
			) : (
				<div className="header-balance flex justify-between">
					{Object.keys(userDetails) == 0 ? (
						<div></div>
					) : (
						<div className="balance flex">
							{/* <div className="aeps-balance flex">
								<div className="img">
									<img src={aeps} alt="aeps-img" />
								</div>
							     <div className="desc">
									<p className="heading">AEPS Balance </p>
									<p className="value">₹ {balance?.AEPS_WALLET?.toFixed(2)}</p>
								</div> 
							</div> */}
							<div className="main-balance flex">
								<div className="img">
									<img src={main} alt="main-wallet-img" />
								</div>
								<div className="desc">
									<p className="heading">Main Balance </p>
									<p className="value">₹ {balance?.MAIN_WALLET?.toFixed(2)}</p>
								</div>
							</div>
						</div>
					)}
					{Object.keys(userDetails) == 0 ? (
						<div className="header-buttons">
							<button className="login" onClick={() => handleModal(true)}>
								Login
							</button>
						</div>
					) : (
						<div className="header-buttons">
							<div className="name ">
								<div className="profile">
									<div className="logoName">
										{/* <img src={userIcon} alt="userIcon" style={{ display: 'inline-block',width:"40px",height:"40px" }} /> */}
										<span> {`${userDetails.firstName.charAt(0) + userDetails.lastName.charAt(0)}`}</span>
									</div>
									{userDetails.firstName} <img src={caretDown} alt="caretDown" style={{ display: 'inline-block' }} />
									<div className="profile-dropdown">
										<ul>
											<li>
												<span onClick={() => logOut('logout')}>Logout</span>
											</li>
											<li>
												<Link to="/user-profile">User Profile</Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			)}

			<div className="top-header">
				<div className="logo">
					<Link to="/dashboard">
						<img src={logo}></img>
					</Link>
				</div>

				<div className={`header-link ${toggle ? 'active' : ''}`}>
					<ul>
						<li>
							<NavLink to="/dashboard">Home</NavLink>
						</li>
						<li>
							<NavLink to="/consumer">Consumer</NavLink>
						</li>
						<li>
							<NavLink to="/business">Business</NavLink>
						</li>
						<li>
							<NavLink to="/company">Company</NavLink>
						</li>

						{userDetails?.firstName && (
							<>
								<li className="d-m-block">
									<NavLink to="/user-profile">User Profile</NavLink>
								</li>
								<li className="dropdown_li">
									<div className="nav-more center" style={{ gap: '10px' }}>
										More <img src={caretDown} alt="caretDown" style={{ display: 'inline-block' }} />
										<div className="profile-dropdown">
											<ul>
												<li>
													<NavLink className="d-m-block" to="/transaction-history">
														Transaction History
													</NavLink>
												</li>
												<li>
													<NavLink to="/wallet-history">Start Traders  Wallet</NavLink>
												</li>
											</ul>
										</div>
									</div>
								</li>

								<li className="d-m-block">
									<NavLink to="/wallet-history">Start Traders  Wallet</NavLink>
								</li>
								<li>
									<NavLink className="d-m-block" to="/transaction-history">
										Transaction History
									</NavLink>
								</li>
							</>
						)}
					</ul>
				</div>
				<div className="header-buttons">
					{Object.keys(userDetails) == 0 ? (
						<>
							<button className="login" onClick={() => handleModal(true)}>
								Login
							</button>
						</>
					) : (
						<>
							<div className="balance flex d-s-none">
								{/* <div className="main-balance flex">
									<div className="img">
										<img src={aeps} alt="aeps-img" />
									</div>
									<div className="desc">
										<p className="heading">AEPS Balance </p>
										<p className="value">₹ {balance?.AEPS_WALLET?.toFixed(2)}</p>
									</div>
								</div> */}
								<div className="main-balance flex">
									<div className="img">
										<img src={main} alt="main-wallet-img" />
									</div>
									<div className="desc">
										<p className="heading">Main Balance </p>
										<p className="value">₹ {balance?.MAIN_WALLET?.toFixed(2)}</p>
									</div>
								</div>
							</div>
							<div className="name d-s-none">
								<div className="profile">
									<div className="logoName">
										{/* <img src={userIcon} alt="userIcon" style={{ display: 'inline-block',width:"40px",height:"40px" }} /> */}
										<span className="profile-text"> {`${userDetails.firstName.charAt(0) + userDetails.lastName.charAt(0)}`}</span>
									</div>
									{userDetails.firstName} <img src={caretDown} alt="caretDown" style={{ display: 'inline-block' }} />
									<div className="profile-dropdown">
										<ul>
											<li>
												<span onClick={() => logOut('logout')}>Logout</span>
											</li>
											<li>
												<Link to="/user-profile">User Profile</Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</>
					)}
					<div className={`toggle-icon ${toggle ? 'active' : ''}`} onClick={spinner}>
						<div className="spinner"></div>
						<div className="spinner"></div>
					</div>
				</div>
			</div>

			{!isEmpty(message) && message.place === 'globle' && (
				<AlertWrapper className={`${message.type} globle`}>
					<span className="close" onClick={() => setMessage({})}>
						×
					</span>
					{message.msg}
				</AlertWrapper>
			)}
		</Wrapper>
	);
}

export default Component;
